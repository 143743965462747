.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f4f4f4;
    color: #333;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
  
  .countdown {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .time-box {
    background-color: #333;
    color: white;
    padding: 20px;
    border-radius: 10px;
    min-width: 30px;
  }
  
  .number {
    font-size: 32px;
    font-weight: bold;
  }
  
  .label {
    font-size: 14px;
    margin-top: 5px;
  }
  
  .subscribe {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input[type="email"] {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    width: 250px;
    text-align: center;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #555;
  }
  