@import "../../styles/variables.scss";

.content-main-container {
  font-size: $font-size-mobile-medium;

  @media (min-width: $mobile-breakpoint) {
    font-size: $font-size-tablet-medium;
  }

  @media (min-width: $tablet-breakpoint) {
    font-size: $font-size-desktop-medium;
  }
  margin: 0px 50px;

  .header-info {
    background-color: #e6e6e6;
    padding: 10px;
    min-height: 50px;
  }

  .pagination-container{
    background-color: #e6e6e6;
    padding: 10px;
    // min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .content-container {
    flex: 1;

    background-color: #e6e6e6;
    overflow-y: auto;

    // background-color: teal;
   height: 440px;
    max-width: 720px;
    padding: 20px;

    .paper {
      min-height: 50px;
      margin-bottom: 10px;
     padding-left: 20px;
     padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

    }

   
    .search-bar {
      background-color: rgb(226, 122, 122);
      height: 40px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 0px 10px;
    }

    .input {
      width: 100%;
      height: 30px;
      border: none;
      background-color: rgb(226, 122, 122);
    }

    .content-header {
      background-color: red;
      color: white;
      position: absolute;
    }

    .header-text {
      font-weight: 500;
    }

    .benefits-container {
      margin-left: 30px;
    }

    .benefits-children {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .divider {
      height: 1px;
      background-color: rgb(52, 45, 45);
      width: 100%;
    }

    // payment modal
    .modal-overlay {
      height: 100%;
      width: 100%;
      background-color: #0cba6f;
    }

    .project-name {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .project-description {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .file-type,
    .account-number {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}
